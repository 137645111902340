import { getAuth } from "firebase/auth";
import { getToken, initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";

const AUTH_UTIL = {
  auth: null,
  appCheck: null,
  init: function (app) {
    AUTH_UTIL.auth = getAuth(app)

    // Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
    // site key and pass it to initializeAppCheck().
    // if (process.env.VUE_APP_RECAPTCHA_ENTERPRISE_KEY) {
    if (!AUTH_UTIL.appCheck && process.env.VUE_APP_RECAPTCHA_ENTERPRISE_KEY) {
      // console.log(`Init ReCaptchaEnterpriseProvider`)
      
      // Init debug provider if we are in dev
      if (process.env.NODE_ENV === 'development') {
        self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
      }

      AUTH_UTIL.appCheck = initializeAppCheck(app, {
        provider: new ReCaptchaEnterpriseProvider(process.env.VUE_APP_RECAPTCHA_ENTERPRISE_KEY),
        isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
      });
    }
  },

  getCurrenUserIdToken: async function () {
    return AUTH_UTIL.auth.currentUser && (await AUTH_UTIL.auth.currentUser.getIdToken())
  },
  getAppCheckToken: async function () {
    try {
      let appCheckToken;
      if (AUTH_UTIL.appCheck) {
        const getTokenResponse = await getToken(AUTH_UTIL.appCheck, /* forceRefresh= */ false);
        if (getTokenResponse) {
          appCheckToken = getTokenResponse.token
        }
      }
      return appCheckToken
    } catch (err) {
        // Handle any errors if the token was not retrieved.
        // console.log(`Error getting app check token ${err}`)
        return;
    }
  }
}

export default AUTH_UTIL
